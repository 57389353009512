<template>
    <div :class="cssClass" :id="`sodoghe${index}`" class="sodoghe-style xs12">
        <div class="buonglai"></div>
        <div class="wheel-font-left"></div>
        <div class="wheel-font-right"></div>
        <div class="wheel-back-left"></div>
        <div class="wheel-back-right"></div>
        <div class="guong-trai"></div>
        <div class="guong-phai"></div>

        <div class="row justify-space-between" style="padding: 0px 8px">
            <div
                style="
                    max-width: 48px;
                    margin: 0 8px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                "
                :style="`width: calc((100%/${TangXe.SoCot}) - 16px);`"
            >
                <div class="donghotrai"></div>
                <div class="donghoto"></div>
                <div class="donghophai"></div>
                <div class="bangdientu"></div>
                <!-- <div class="ghelai"></div>
                <div class="tayghelaixe"></div>
                <div class="-laixe"></div> -->
                <i
                    class="mdi mdi-steering"
                    style="position: relative; z-index: 2; top: 28px"
                    :style="`opacity: ${index == 0 ? 1 : 0.3}`"
                ></i>
            </div>
        </div>
        <div
            style="position: relative; z-index: 1"
            :style="`height: calc(100% - ${ShowCheckBox ? '112px' : '88px'}); `"
        >
            <div class="xs12 scroll-sodoghe-style">
                <!-- style="padding-right: 30px" -->
                <div
                    v-for="(dong, IndexHang) in TangXe.SoHang"
                    :key="IndexHang"
                    class="hang-xe row justify-space-between"
                    :style="`${
                        ShowCheckBox ? 'width: calc(100% - 24px);' : 'width: calc(100%)'
                    }; height: calc(100%/${TangXe.SoHang} - 16px);`"
                    :class="TangXe.SoHang - 1 == IndexHang ? '' : 'mb-3'"
                >
                    <div
                        v-for="(cot, IndexCot) in TangXe.SoCot"
                        :key="IndexCot"
                        :style="`width: calc((100%/${TangXe.SoCot}) - 16px);`"
                        class="vitri"
                    >
                        <div
                            class="cho-ngoi ghe-lai"
                            v-if="index == 0 && IndexHang == 0 && IndexCot == 0"
                        >
                            <div class="khung-ghe"></div>

                            <div class="than-ghe">Lái xe</div>

                            <div class="lung-ghe"></div>
                        </div>

                        <div
                            class="cho-ngoi"
                            v-else-if="CoThongTinCho(IndexHang, IndexCot)"
                            :class="{
                                active: KiemTraChoDangChon(IndexHang, IndexCot),
                                disabled:
                                    KiemTraChoVoHieuHoa(IndexHang, IndexCot) ||
                                    GetThongTinCho(IndexHang, IndexCot).KieuCho.kyHieu ==
                                        $t('KyHieuChoNgoi.KyHieuNhaVeSinh') ||
                                    GetThongTinCho(IndexHang, IndexCot).KieuCho.kyHieu ==
                                        $t('KyHieuChoNgoi.KyHieuCua'),
                            }"
                            @click="
                                EnableSelect && !KiemTraChoVoHieuHoa(IndexHang, IndexCot)
                                    ? ChonCho(IndexHang, IndexCot)
                                    : null
                            "
                        >
                            <div class="khung-ghe"></div>
                            <div
                                class="nha-ve-sinh"
                                v-if="
                                    GetThongTinCho(IndexHang, IndexCot).KieuCho.kyHieu ==
                                    $t('KyHieuChoNgoi.KyHieuNhaVeSinh')
                                "
                            >
                                <i class="mdi mdi-toilet"></i>
                            </div>
                            <div
                                class="cua-xe"
                                v-else-if="
                                    GetThongTinCho(IndexHang, IndexCot).KieuCho.kyHieu ==
                                    $t('KyHieuChoNgoi.KyHieuCua')
                                "
                            >
                                <i class="mdi mdi-door-open"></i>
                            </div>
                            <div class="than-ghe" v-else>
                                {{ GetTenCho(IndexHang, IndexCot) }}
                            </div>

                            <div class="lung-ghe"></div>
                        </div>
                        <div
                            class="cho-trong"
                            v-else-if="!CoThongTinCho(IndexHang, IndexCot)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="thanhxe"></div>
    </div>
</template>

<script>
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxContextMenu } from "devextreme-vue";
import { mapState } from "vuex";
export default {
    components: {
        DxCheckBox,
        DxContextMenu,
    },
    props: {
        index: { type: Number, default: 0 },
        cssClass: { type: String, default: "" },
        ShowCheckBox: { type: Boolean, default: true },
        EnableSelect: { type: Boolean, default: true },
        TangXe: { type: Object, default: () => {} },
        // DanhSachSoDoCho: { type: Array, default: () => [] },
    },
    data() {
        return {
            DanhSachChoDangChonTheoTang: [],
            DanhSachChoVoHieuHoaTheoTang: [],
            SoDoChoTheoTang: [],
        };
    },
    computed: {
        ...mapState({
            // SoDoCho: (state) => state.ChuyenDi.SoDoCho,
        }),
        SoDoCho: {
            get() {
                return this.$store.state.ChuyenDi.SoDoCho;
            },
            set(data) {
                this.$store.commit("ChuyenDi/Set", {
                    key: "SoDoCho",
                    data: data,
                });
            },
        },
        DanhSachChoDangChonTheoXe: {
            get() {
                return this.$store.state.ChuyenDi.DanhSachChoDangChonTheoXe;
            },
            set(data) {
                this.$store.commit("ChuyenDi/Set", {
                    key: "DanhSachChoDangChonTheoXe",
                    data: data,
                });
            },
        },
        DanhSachChoVoHieuHoaTheoXe: {
            get() {
                return this.$store.state.ChuyenDi.DanhSachChoVoHieuHoaTheoXe;
            },
            set(data) {
                this.$store.commit("ChuyenDi/Set", {
                    key: "DanhSachChoVoHieuHoaTheoXe",
                    data: data,
                });
            },
        },
    },
    watch: {
        SoDoCho: {
            handler: function () {
                this.SoDoChoTheoTang = this.SoDoCho[0]
                    ? this.SoDoCho[0].DanhSachChoNgoi
                    : [];
                // if (this.Build) {
                //     this.MapTenCho();
                // }
            },
            deep: true,
            immediate: true,
        },
        DanhSachChoDangChonTheoXe: {
            handler: function () {
                if (!this.DanhSachChoDangChonTheoXe[this.index]) {
                    this.DanhSachChoDangChonTheoXe[this.index] = {
                        IndexTang: this.index,
                        DanhSachChoDangChonTheoTang: [],
                    };
                }
            },
            immediate: true,
            deep: true,
        },
        DanhSachChoVoHieuHoaTheoXe: {
            handler: function () {
                if (!this.DanhSachChoVoHieuHoaTheoXe[this.index]) {
                    this.DanhSachChoVoHieuHoaTheoXe[this.index] = {
                        IndexTang: this.index,
                        DanhSachChoVoHieuHoaTheoTang: [],
                    };
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        MapTenCho() {
            let count = 0;
            if (this.SoDoChoTheoTang.length == 0) return;
            this.SoDoCho.forEach((e, i) => {
                if (i < this.index) {
                    count +=
                        i == 0
                            ? e.DanhSachChoNgoi.length - 1
                            : e.DanhSachChoNgoi.length || 0;
                }
            });
            for (let index = 0; index < this.TangXe.SoCot; index++) {
                for (let jndex = 0; jndex < this.TangXe.SoHang; jndex++) {
                    if (!(this.index == 0 && index == 0 && jndex == 0)) {
                        this.SoDoChoTheoTang.forEach((e) => {
                            if (e.IndexCot == index && e.IndexHang == jndex) {
                                count++;
                                e.TenCho = e.KyHieu + "-" + count;
                                e.MaCho = e.KyHieu + "-" + count;
                            }
                        });
                    }
                }
            }
            setTimeout(() => {
                if (this.SoDoCho[0]) {
                    this.SoDoCho[0].DanhSachChoNgoi = this.SoDoChoTheoTang;
                }
            }, 200);
        },
        ResetCheckBox() {
            for (let index = 0; index < this.TangXe.SoCot; index++) {
                this.ArrCheckCot[index] = false;
            }
            for (let index = 0; index < this.TangXe.SoHang; index++) {
                this.ArrCheckHang[index] = false;
            }
        },
        Check_DanhSachSoDoCho(IndexHang, IndexCot) {
            return this.SoDoChoTheoTang.some(
                (e) => e.IndexHang == IndexHang && e.IndexCot == IndexCot,
            );
        },
        Check_DanhSachChoDangChon(IndexHang, IndexCot) {
            return this.DanhSachChoDangChonTheoXe[
                this.index
            ].DanhSachChoDangChonTheoTang.some(
                (e) => e.IndexHang == IndexHang && e.IndexCot == IndexCot,
            );
        },
        Check_DanhSachChoVoHieuHoa(IndexHang, IndexCot) {
            return this.DanhSachChoVoHieuHoaTheoXe[
                this.index
            ].DanhSachChoVoHieuHoaTheoTang.some(
                (e) => e.IndexHang == IndexHang && e.IndexCot == IndexCot,
            );
        },
        CoThongTinCho(IndexHang, IndexCot) {
            if (this.SoDoChoTheoTang.length == 0) return false;

            return this.Check_DanhSachSoDoCho(IndexHang, IndexCot);
        },
        KiemTraChoDangChon(IndexHang, IndexCot) {
            if (
                !this.DanhSachChoDangChonTheoXe[this.index] ||
                (!!this.DanhSachChoDangChonTheoXe[this.index] &&
                    this.DanhSachChoDangChonTheoXe[this.index].DanhSachChoDangChonTheoTang
                        .length == 0)
            ) {
                return false;
            }

            return this.Check_DanhSachChoDangChon(IndexHang, IndexCot);
        },
        KiemTraChoVoHieuHoa(IndexHang, IndexCot) {
            if (
                !this.DanhSachChoVoHieuHoaTheoXe[this.index] ||
                (!!this.DanhSachChoVoHieuHoaTheoXe[this.index] &&
                    this.DanhSachChoVoHieuHoaTheoXe[this.index]
                        .DanhSachChoVoHieuHoaTheoTang.length == 0)
            ) {
                return false;
            }

            return this.Check_DanhSachChoVoHieuHoa(IndexHang, IndexCot);
        },
        ChonCho(IndexHang, IndexCot) {
            // Chon chỗ theo tầng
            let check = this.Check_DanhSachChoDangChon(IndexHang, IndexCot);
            let item = this.SoDoChoTheoTang.find(
                (e) => e.IndexHang == IndexHang && e.IndexCot == IndexCot,
            );
            if (
                item &&
                (item.KieuCho.kyHieu == this.$t("KyHieuChoNgoi.KyHieuNhaVeSinh") ||
                    item.KieuCho.kyHieu == this.$t("KyHieuChoNgoi.KyHieuCua"))
            ) {
                return;
                // return this.$Helper.ThongBaoToast(
                //     "warning",
                //     "Không thể bán vé tại vị trí nhà vệ sinh!",
                // );
                // return this.$Helper.ThongBaoToast(
                //     "warning",
                //     "Không thể bán vé tại vị trí cửa!",
                // );
            }
            if (!check) {
                this.DanhSachChoDangChonTheoXe[
                    this.index
                ].DanhSachChoDangChonTheoTang.push(item);
            } else {
                let itemDangChon = this.DanhSachChoDangChonTheoXe[
                    this.index
                ].DanhSachChoDangChonTheoTang.find(
                    (e) => e.IndexHang == IndexHang && e.IndexCot == IndexCot,
                );
                let index = this.DanhSachChoDangChonTheoXe[
                    this.index
                ].DanhSachChoDangChonTheoTang.indexOf(itemDangChon);
                this.DanhSachChoDangChonTheoXe[
                    this.index
                ].DanhSachChoDangChonTheoTang.splice(index, 1);
            }
        },
        GetTenCho(IndexHang, IndexCot) {
            let item = this.SoDoChoTheoTang.find(
                (e) => e.IndexCot == IndexCot && e.IndexHang == IndexHang,
            );
            if (item) {
                return item.TenCho;
            }
            return "";
        },
        GetThongTinCho(IndexHang, IndexCot) {
            let item = this.SoDoChoTheoTang.find(
                (e) => e.IndexCot == IndexCot && e.IndexHang == IndexHang,
            );
            return item;
        },
        ChonTatCa(bool) {
            if (!bool) {
                this.DanhSachChoDangChonTheoXe[
                    this.index
                ].DanhSachChoDangChonTheoTang = [];
            } else {
                for (let index = 0; index < this.TangXe.SoCot; index++) {
                    for (let jndex = 0; jndex < this.TangXe.SoHang; jndex++) {
                        if (!(this.index == 0 && index == 0 && jndex == 0)) {
                            let check = this.DanhSachChoVoHieuHoaTheoXe[
                                this.index
                            ].DanhSachChoVoHieuHoaTheoTang.some(
                                (e) => e.IndexHang == jndex && e.IndexCot == index,
                            );
                            if (!check) {
                                let data = {
                                    IndexHang: jndex,
                                    IndexCot: index,
                                };
                                this.DanhSachChoDangChonTheoXe[
                                    this.index
                                ].DanhSachChoDangChonTheoTang.push(data);
                            }
                        }
                    }
                }
            }
        },
    },
    created() {
        if (!this.DanhSachChoDangChonTheoXe[this.index]) {
            this.DanhSachChoDangChonTheoXe[this.index] = {
                IndexTang: this.index,
                DanhSachChoDangChonTheoTang: [],
            };
        }

        if (!this.DanhSachChoVoHieuHoaTheoXe[this.index]) {
            this.DanhSachChoVoHieuHoaTheoXe[this.index] = {
                IndexTang: this.index,
                DanhSachChoVoHieuHoaTheoTang: [],
            };
        }
    },
    mounted() {},
};
</script>

<style>
:root {
    --distance: 6px;
    --radius: 16px;
    ---distance: -6px;
}
</style>

<style scoped>
.rotate {
    transform: rotate(-90deg);
}
.sodoghe-style {
    height: 100%;
    width: 100%;
    max-width: calc((100vh - 48px - 56px - 64px) * 0.58);
    position: relative;
}
.thanhxe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #aaa;
    border-radius: 16px;
    z-index: 0;
    background-color: #cccccc20;
}

.wheel-font-left {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 20%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-font-right {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 20%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.wheel-back-left {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 80%;
    background: #000;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
    left: var(---distance);
}
.wheel-back-right {
    position: absolute;
    width: var(--distance);
    height: 48px;
    top: 80%;
    background: #000;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
    right: var(---distance);
}
.guong-trai {
    position: absolute;
    left: -16px;
    top: -14px;
    height: 32px;
    border-radius: 60px;
    border: 6px solid #ccc;
    border-top: 10px solid #ccc;
    padding: 4px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
}
.guong-phai {
    position: absolute;
    right: -16px;
    top: -14px;
    height: 32px;
    border-radius: 60px;
    border: 6px solid #ccc;
    border-top: 10px solid #ccc;
    padding: 4px;
    border-bottom-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
}
.buonglai {
    position: absolute;
    width: calc(100% - 12px);
    height: 28px;
    background: #dadce0;
    border-radius: 16px;
    top: 8px;
    left: 6px;
}
.donghoto {
    width: 24px;
    height: 16px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.bangdientu {
    width: 28px;
    height: 16px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: 64px;
    border-radius: 2px;
}
.donghotrai {
    width: 16px;
    height: 16px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: -4px;
    border-radius: 50px;
}
.donghophai {
    width: 16px;
    height: 16px;
    background: #fff;
    position: absolute;
    top: 14px;
    left: 44px;
    border-radius: 50px;
}
.ghelai {
    position: absolute;
    background: #fff;
    width: 40px;
    height: 24px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    bottom: -8px;
    z-index: 2;
}
.tayghelaixe {
    z-index: 0;
    width: calc(100% + 8px);
    height: 20px;
    background: #ddd;
    position: absolute;
    bottom: -12px;
    left: -4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.-laixe {
    position: absolute;
    z-index: 2;
    background: #ddd;
    width: 24px;
    height: 4px;
    bottom: -8px;
    left: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.hang-xe {
    width: 100%;
    min-height: 36px;
    /* display: block; */
}
.hang-xe .vitri {
    margin: 0 8px;
    float: left;
    max-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cho-ngoi {
    position: relative;
    width: 100%;
    height: 40px;
    cursor: pointer;
}
.them-cho {
    position: relative;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadce0;
    cursor: pointer;
}
.them-cho.active,
.cho-ngoi.active .than-ghe {
    background: #03a9f4;
}
.them-cho.active i,
.cho-ngoi.active .than-ghe {
    color: #fff;
}

.cho-ngoi.disabled {
    background: #fff;
    color: unset;
    opacity: 0.5;
    cursor: no-drop;
}

.disabled .than-ghe {
    background: #000;
    color: #fff;
}

.khung-ghe {
    z-index: 0;
    width: calc(100% + 8px);
    height: calc(100% - 4px);
    background: #d6d6d6;
    position: absolute;
    top: 8px;
    left: -4px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.than-ghe {
    height: 100%;
    width: 100%;
    z-index: 2;
    background: #fff;
    border: 1px solid #d6d6d6;
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lung-ghe {
    position: absolute;
    z-index: 3;
    background: #d6d6d6;
    width: calc(100% - 16px);
    height: 4px;
    bottom: 0;
    left: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.nha-ve-sinh,
.cua-xe {
    height: calc(100% + 4px);
    width: calc(100% + 8px);
    z-index: 2;
    background: #d6d6d6;
    color: red;
    border: 1px solid #d6d6d6;
    position: relative;
    left: -4px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scroll-sodoghe-style {
    margin-top: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 4px 8px;
    margin-right: 8px;
}
.scroll-sodoghe-style::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
.scroll-sodoghe-style::-webkit-scrollbar-track {
    border-radius: 10px;
}
.scroll-sodoghe-style::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
}
.scroll-sodoghe-style::-webkit-scrollbar-thumb:active {
    background: #ccc;
}
</style>
